<template>
  <div class="room-selector" :style="{zoom:zoom}">
    <div ref="roomWapper" class="room-wapper" >
      <div class="info"> {{id}}{{ typeInfo.type }}{{typeInfo.categoryIndex}}</div>
      <div
        :ref="`room-${index}`"
        v-for="(room, index) in typeInfo.rooms"
        :key="index"
        :style="room.style"
        @mouseover="handleMouseover(room.layers, index)"
        @mouseleave="handleMouseout(room.layers, index)"
        @click="handleRoomClick(room,index)"
        class="room"
      > 
         <img class="status" v-if="room.no === selected "  :src="room.layers.selected"  alt="">
         <img class="status" v-if="room.data['leaseStatus'] === '0' && !(room.no === selected)"  :src="room.layers.normal"  alt="">
         <img class="status" v-if="room.data['leaseStatus'] === '2' && !(room.no === selected)"  :src="room.layers.disabled"  alt="">
        <div class="room-number">{{room.data["roomNumber"] }}</div>
        <!-- {{ index }} -->
      </div>
    </div>
    <!-- <div class="type-">
     </div> -->
  </div>
</template>
<script>
import roomsTypeData from "./types.json";
/** @desc 设计稿容器宽度 用于计算缩放倍率 */
const BASE_WIDTH = 688
console.log(`房型数据导入`, roomsTypeData);
export default {
  name: "RoomSelector",
  data() {
    return {
      roomsTypeData: roomsTypeData,
      selected:""
    };
  },
  props: {
    type: {
      type: String,
      default: "A",
    },
    roomData:{
      type:Object
    },
    width: {
      type: Number,
      default:688
    },
    /** @desc 楼层 */
    floor:{
      type:String,
      required:true
    },
    /** @desc 大楼编号 */
    build:{
      type:String,
      required:true
    },

    item: {},
  },
  computed: {
    id(){
      /**
       * 因为厂房id是b1 形式 取最后一位，当前厂房只有 1-8栋，时间紧迫 暂不考虑 b9以上兼容， 后续若有需要 再做调整
       */
      return this.build.substr(-1) + this.floor
    },
    typeInfo() {
      let info = {};
      for (let key in this.roomsTypeData) {
        let typeData = this.roomsTypeData[key];
        let categoryIndex = typeData.category.findIndex((c) =>
          c.rooms.find((c) => c === this.id)
        );
        if (categoryIndex !== -1) {
          info["type"] = key;
          info["categoryIndex"] = categoryIndex;
          info["category"] = typeData.category[categoryIndex];
          info["layers"] = info["category"].layers;
          info["rooms"] = typeData.rooms.map((item, index) => {
            return {
              ...item,
              layers: info.layers[index],
              style: {
                width: `${item.width}px`,
                height: `${item.height}px`,
                top: `${item.top}px`,
                left: `${item.left}px`,
                zIndex: item.zindex,
                opacity: 0.5,
                backgroundSize: `100% 100%`,
                backgroundImage: `url(${info.layers[index].normal})`,
              },
              hover:false,
              data:this.getRoomData(item.no),
              selected:false
            };
          });
          break;
        }
      }
      return info;
    },
    roomType() {
      return `type-${this.type.toLowerCase()}`;
    },
    roomQuanity() {
      return this.roomQuanityMap[this.type];
    },
    zoom(){
      return this.width / BASE_WIDTH    
    },
   
  },
  methods: {
    handleRoomClick(room){
      console.log("房间点击",room)
      if(room.data.leaseStatus!=="2" || 1){
        // room.selected = !room.selected
        this.selected =   this.selected === room.no ? "" :room.no
        console.log("房间可选 更改状态",room)
        this.$emit("change", this.selected === room.no ? {...room.data,url:room.layers.selected} : undefined)
      }
    },
    handleMouseover(layer, index) {
      // console.log("鼠标移动过", layer, this.$refs[`room-${index}`][0]);
      this.$refs[
        `room-${index}`
      ][0].style.backgroundImage = `url(${layer.selected})`;
    },
    getRoomData(index){
        return this.roomData[this.floor + `0${index}`] || {}
    },
    handleMouseout(layer, index) {
      // console.log("鼠标移动过", layer, this.$refs[`room-${index}`][0]);
      this.$refs[
        `room-${index}`
      ][0].style.backgroundImage = `url(${layer.normal})`;
    },
    select(n) {
      // this.info = this.item[500 + n];  //当前楼层 的 某个房间信息
      // let suffix = this.info.leaseStatus != 0 ? '_disabled.png' : '_normal.png'; //图片后缀
      // this.info.url = require(`../../../../assets/img/smartLocation/lc/lc_img_3A${n}${suffix}`); // 详情图
      // this.$emit('select', this.info);
    },
  },
  mounted() {
    console.log("生成typeinfo", this.typeInfo);

  

    // console.log("缩放倍率调试",`设计稿宽度:${BASE_WIDTH},当前宽度${curWidth},缩放倍率${curWidth / BASE_WIDTH}`)
  },
};
</script>
<style lang="scss" scoped>
.room-selector {
  // width: 100%;
  // height:300px;
  background: white;
  box-sizing: border-box;
  position: relative;
  display: inline-block;
  .info{
    position: absolute;
    left:10px;
    opacity:.5;
    font-size:12px;
    top:10px;
  }
  .room-wapper {
    height: 450px;
    width: 688px;
    border: 10px solid #0E459C;
    padding: 30px;
    position: relative;
    border-radius: 5px;
    .room {
      position: absolute;
      background-repeat: no-repeat;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      .status{
        position: absolute;
        width: 100%;
        height: 100%;
      }
      .room-number{
        position: absolute;
      }
    }
  }
  [class*="type-"] {
    // 房间类型公共样式
    height: 450px;
    width: 661px;
    border: 10px solid rgb(7, 144, 218);
    padding: 30px;
    position: relative;

    &::before {
      content: "";
      width: 40px;
      height: 15px;
      display: inline-block;
      border: 1px solid #000;
      position: absolute;
      top: 30px;
      left: 30px;
      background: white;
    }
    &::after {
      content: "";
      width: 40px;
      height: 15px;
      border: 1px solid #000;
      position: absolute;
      bottom: 30px;
      right: 30px;
      background: white;
    }
    //单个房间公共样式
    [class*="room-"] {
      position: absolute;
      background-repeat: no-repeat;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .type-a {
    .room-1 {
      width: 175px;
      height: 367px;
      top: 30px;
      // background:url(../../../../assets/img/smartLocation/lc2/img_1_1_2_n.png);
      &:hover {
        // background:url(../../../../assets/img/smartLocation/lc2/img_1_1_2_s.png);
      }
    }
    .room-2 {
      width: 326px;
      height: 86px;
      left: 210px;
      // background:url(../../../../assets/img/smartLocation/lc2/img_1_1_3_n.png);
      z-index: 1;
      &:hover {
        // background:url(../../../../assets/img/smartLocation/lc2/img_1_1_3_s.png);
      }
    }
    .room-3 {
      width: 368px;
      height: 367px;
      left: 210px;
      // background:url(../../../../assets/img/smartLocation/lc2/img_1_1_4_n.png);
      &:hover {
        // background:url(../../../../assets/img/smartLocation/lc2/img_1_1_4_s.png);
      }
    }
  }
  .type-b {
    .room-1 {
      width: 306px;
      height: 367px;
      // background:url(../../../../assets/img/smartLocation/lc2/img_7_1_2_n.png);
      &:hover {
        // background:url(../../../../assets/img/smartLocation/lc2/img_7_1_2_s.png);
      }
    }
    .room-2 {
      width: 326px;
      height: 86px;
      left: 211px;
      z-index: 1;
      // background:url(../../../../assets/img/smartLocation/lc2/img_7_1_3_n.png);
      &:hover {
        // background:url(../../../../assets/img/smartLocation/lc2/img_7_1_3_s.png);
      }
    }
    .room-3 {
      width: 238px;
      height: 367px;
      left: 341px;
      // background:url(../../../../assets/img/smartLocation/lc2/img_7_1_4_n.png);
      &:hover {
        // background:url(../../../../assets/img/smartLocation/lc2/img_7_1_4_s.png);
      }
    }
  }
  .type-c {
    .room-1 {
      width: 547px;
      height: 368px;
      // background:url(../../../../assets/img/smartLocation/lc2/img_1_2_2_n.png);
      &:hover {
        // background:url(../../../../assets/img/smartLocation/lc2/img_1_2_2_s.png);
      }
    }
  }
}
</style>
